<template>
	<div>
		<gc-dialog :title="isModifyStatus ? '修改账户' : '账户管理'" class="account-manage" :show.sync="visible">
			<template>
				<el-form ref="accountForm" label-position="top" :model="accountModel" :rules="accountModelRules">
					<el-form-item label="登录账号" prop="account">
						<el-input v-model="accountModel.account" disabled></el-input>
					</el-form-item>
					<el-form-item label="登录密码" prop="key">
						<el-input
							:disabled="!isModifyStatus"
							:type="isShowPassword && isModifyStatus ? 'text' : 'password'"
							v-model="accountModel.key"
							:autoComplete="isShowPassword ? '' : 'new-password'"
						>
							<!-- <template slot="append"
                ><i
                  class="iconfont"
                  :class="isShowPassword ? 'icon-zhengyan' : 'icon-biyan'"
                  @click="showPassword"
                ></i
              ></template> -->
						</el-input>
					</el-form-item>
				</el-form>
			</template>
			<template #footer>
				<div class="btn-box">
					<el-button
						type="primary"
						round
						v-show="!isModifyStatus"
						class="btn-box-item modify"
						@click="modifyAccountKey"
					>
						修 改
					</el-button>
					<el-button plain round v-show="isModifyStatus" class="btn-box-item cancel" @click="cancelModify">
						取 消
					</el-button>
					<el-button
						round
						type="primary"
						v-show="isModifyStatus"
						class="btn-box-item save"
						@click="saveModify"
					>
						保 存
					</el-button>
				</div>
			</template>
		</gc-dialog>
	</div>
</template>

<script>
import { ruleRequired, RULE_PASSWORD, ruleMaxLength, ruleMinLength } from '@/utils/rules'
export default {
	name: 'ModifyAccount',
	data() {
		return {
			visible: false,
			accountModel: {
				account: '',
				key: '',
			},
			accountModelRules: {
				account: [ruleRequired('请输入账号')],
				key: [
					ruleRequired('密码不能为空'),
					RULE_PASSWORD,
					ruleMaxLength(18, '密码长度'),
					ruleMinLength(6, '密码长度'),
				],
			},
			isModifyStatus: false,
			isShowPassword: false,
		}
	},
	methods: {
		modifyAccountKey() {
			this.isModifyStatus = true
			this.accountModel.key = ''
			setTimeout(() => {
				this.$refs.accountForm.clearValidate()
			}, 10)
		},
		cancelModify() {
			this.isModifyStatus = false
			this.$refs.accountForm && this.$refs.accountForm.resetFields()
		},
		saveModify() {
			this.$refs.accountForm.validate(() => {})
		},
		show() {
			this.visible = true
			console.log(this.accountModel)
		},
		showPassword() {
			if (!this.isModifyStatus) return
			this.isShowPassword = !this.isShowPassword
		},
	},
}
</script>
<style lang="scss" scoped></style>
