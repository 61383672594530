/* 待办：1、已开通设备类型 动态sub-title 2、tenantId取真实数据 */
<template>
	<div class="page">
		<div class="page-main">
			<div class="page-main-left">
				<gc-custom-search
					key-word="设备类型"
					:search.sync="searchDeviceType"
					placeholder="请输入检索关键字"
					@on-keyup-enter="getUnGrantedList(0)"
				>
					<i class="el-icon-search" slot="icon" style="cursor: pointer" @click="getUnGrantedList(0)"></i>
				</gc-custom-search>
				<gc-table
					v-loading="loading"
					class="table"
					ref="gcTable"
					:columns="columns"
					:table-data="tableData"
					:border="true"
					:show-page="true"
					:current-page="page.current"
					:total="page.total"
					:page-size="page.size"
					@size-change="handleSizeChange"
					@current-page-change="getUnGrantedList"
				></gc-table>
			</div>
			<div class="page-main-right">
				<gc-sub-title :text="`已开通设备类型/${grantedList.length}种`" icon=""></gc-sub-title>
				<div class="device-type-list">
					<div class="device-type-item" v-for="(item, idx) in grantedList" :key="item.deviceTypeId">
						<div class="list-style">{{ idx + 1 }}.&nbsp;</div>
						<div class="left">
							<div class="name">
								{{ item.deviceTypeName }}
							</div>
							<div class="desc">
								{{ item.firstCategory }}{{ item.firstCategory && '|' }} {{ item.secondCategory
								}}{{ item.secondCategory && '|' }}
								{{ item.manufacturerName }}
							</div>
						</div>
						<i class="iconfont icon-shanchu" @click="handleDelete(item)"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	apiGetGrantedDeviceTypeList,
	apiGetUnGrantedDeviceTypeList,
	apiCancelDeviceType,
	apiGrantDeviceType,
} from '@/apis/tenant.api.js'
export default {
	name: 'Device',
	components: {},
	props: {},
	data() {
		return {
			searchDeviceType: '',
			columns: [
				{
					key: 'deviceTypeName',
					name: '设备类型',
					tooltip: true,
					fixed: true,
				},
				{
					key: 'firstCategory',
					name: '设备大类',
				},
				{
					key: 'secondCategory',
					name: '设备小类',
				},
				{
					key: 'manufacturerName',
					name: '厂商名称',
				},
				{
					key: 'operate',
					name: '操作',
					render: (h, row) => {
						return h(
							'span',
							{
								class: 'grant',
								on: {
									click: () => this.handleGrant(row),
								},
							},
							'授予',
						)
					},
				},
			],
			tableData: [],
			page: {
				current: 0,
				total: 0,
				pages: 0,
				size: 20,
			},
			grantedList: [],
			loading: false,
		}
	},
	computed: {
		tenantId() {
			return this.$route.query.tenantId
		},
	},
	watch: {},
	created() {},
	activated() {
		this.getUnGrantedList(0)
		this.getGrantedList()
	},
	methods: {
		handleGrant({ deviceTypeId }) {
			apiGrantDeviceType({
				deviceTypeIds: [deviceTypeId],
				tenantId: this.tenantId,
			}).then(() => {
				this.getUnGrantedList(0)
				this.getGrantedList()
			})
		},
		getGrantedList() {
			apiGetGrantedDeviceTypeList({
				tenantId: this.tenantId,
			}).then(({ records }) => {
				this.grantedList = records
			})
		},
		handleSizeChange(size) {
			this.page.size = size
			this.getUnGrantedList(1)
		},
		getUnGrantedList(current) {
			this.loading = true
			apiGetUnGrantedDeviceTypeList({
				current,
				deviceTypeName: this.searchDeviceType,
				size: this.page.size,
				tenantId: this.tenantId,
			})
				.then(({ records, ...page }) => {
					this.tableData = records
					this.page = page
					this.$refs?.gcTable?.$refs.gcCloudTable?.doLayout()
				})
				.finally(() => {
					this.loading = false
				})
		},
		handleDelete({ deviceTypeId }) {
			this.$confirm(
				'你确定要删除该已开通设备类型吗？删除后该设备类型将不能被继续使用，已使用者不会受到影响',
			).then(() => {
				apiCancelDeviceType({
					deviceTypeIds: [deviceTypeId],
					tenantId: this.tenantId,
				}).then(() => {
					this.$message.success('操作成功')
					this.getUnGrantedList(0)
					this.getGrantedList()
				})
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.page-main-left {
	flex: 1;
	height: 100%;
	padding-bottom: 0;
	::v-deep {
		.grant {
			font-size: 14px;
			color: #4d6bff;
			line-height: 21px;
			cursor: pointer;
		}
		.gc-custom-search {
			width: 100% !important;
		}
		.gc-table {
			margin-top: 20px;
			height: calc(100% - 60px);
		}
	}
}
.page-main-right {
	flex: 0 0 420px;
	display: flex;
	flex-direction: column;
	.device-type-list {
		flex: 1;
		margin-top: 21px;
		// margin: 21px 0 24px;
		// overflow-y: scroll;
		@include base-scrollbar(-6px);
		overflow: auto;
		.device-type-item {
			margin-top: 20px;
			display: flex;
			align-items: flex-start;
			.list-style {
				font-size: 14px;
				font-weight: 600;
				color: #666666;
				line-height: 20px;
			}
			.left {
				flex: 1;
				.name {
					max-width: 246px;
					font-size: 14px;
					font-weight: 600;
					color: #666666;
					line-height: 18px;
					word-wrap: break-word;
					word-break: break-all;
				}
				.desc {
					max-width: 226px;
					margin-top: 10px;
					box-sizing: border-box;
					word-wrap: break-word;
					word-break: break-all;
					font-size: 12px;
					color: #666666;
					line-height: 14px;
				}
			}
			.icon-shanchu {
				color: #e64550;
				font-size: 14px;
				line-height: 18px;
			}
		}
	}
}
</style>
