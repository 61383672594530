<template>
	<div class="update-data-isolation-level">
		<gc-dialog
			title="设置数据隔离级别"
			width="480px"
			:show.sync="visible"
			:close-on-press-escape="false"
			:show-close="false"
			:show-cancel-btn="false"
			@ok="handleUpdate"
			@cancel="visible = false"
		>
			<el-tree
				ref="tree"
				node-key="id"
				default-expand-all
				highlight-current
				:data="treeData"
				:expand-on-click-node="false"
			>
				<div class="custom-tree-node" slot-scope="{ data }">
					<template>
						<el-radio-group v-model="level">
							<el-radio :label="data.id">{{ data.name }}</el-radio>
						</el-radio-group>
					</template>
				</div>
			</el-tree>
			<div class="tip">请设置组织隔离级别，否则该组织层级不生效</div>
		</gc-dialog>
	</div>
</template>

<script>
import { apiSetIsolationBetweenOrg } from '@/apis/tenant.api'
export default {
	name: 'IsolationSet',
	components: {},
	props: {
		orgStruct: {},
	},
	data() {
		return {
			visible: false,
			level: '',
		}
	},
	computed: {
		isEmpty() {
			return this.treeData.length === 0
		},
		tenantId() {
			return this.$route.query.tenantId
		},
		treeData() {
			return this._.cloneDeep(this.orgStruct)
		},
	},
	watch: {
		visible(newVal) {
			if (!newVal) {
				this.level = ''
			}
		},
	},
	created() {},
	mounted() {},
	methods: {
		showDialog(level) {
			this.level = level
			this.visible = true
		},
		handleUpdate() {
			if (!this.level) {
				return this.$message.warning('请至少选择一级组织机构')
			}
			this.$confirm('你确定设置该层级数据隔离吗？保存后无法进行修改。').then(() => {
				apiSetIsolationBetweenOrg({
					tenantId: this.tenantId,
					orgStructId: this.level,
				}).then(() => {
					this.visible = false
					this.$message.success('设置成功')
					this.$emit('refresh')
				})
			})
		},
		closeDialog() {
			this.level = ''
			this.showDialog = false
		},
	},
}
</script>
<style lang="scss" scoped>
.tip {
	margin: 30px 0 0 24px;
	font-size: 12px;
	color: #ff9d57;
	line-height: 16px;
	// text-align: right;
}
</style>
