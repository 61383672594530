<template>
	<div class="page operate">
		<gc-table
			class="table"
			:columns="columns"
			:table-data="tableData"
			:border="true"
			:show-page="true"
			:current-page="page.current"
			:total="page.total"
			:page-size="page.size"
			@size-change="handleSizeChange"
			@current-page-change="getOperateList"
		></gc-table>
	</div>
</template>

<script>
import { apiGetOperateList } from '@/apis/tenant.api'

export default {
	name: 'Operate',
	components: {},
	props: {
		curTabName: String,
	},
	data() {
		return {
			columns: [
				{
					key: 'actionType',
					name: '操作类型',
				},
				{
					key: 'actionContent',
					name: '操作内容',
				},
				{
					key: 'actionStaffName',
					name: '操作人',
				},
				{
					key: 'createTime',
					name: '操作时间',
					minWidth: '170',
				},
			],
			tableData: [],
			page: {
				current: 0,
				total: 0,
				size: 20,
			},
		}
	},
	computed: {
		tenantId() {
			return this.$route.query.tenantId
		},
	},
	watch: {
		curTabName: {
			immediate: true,
			handler(newVal) {
				if (newVal === 'operate') {
					this.getOperateList(1)
				}
			},
		},
	},
	methods: {
		handleSizeChange(size) {
			this.page.size = size
			this.getOperateList(1)
		},
		// 操作记录列表
		getOperateList(current) {
			apiGetOperateList({
				tenantId: this.tenantId,
				page: current - 1,
				size: this.page.size,
			}).then(({ dataList, current, total }) => {
				this.tableData = dataList || []
				this.page.current = current + 1
				this.page.total = total
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.operate {
	padding: 30px 24px 0 24px;
}
</style>
